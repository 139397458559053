import React, { lazy, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SuspenseContent from "./containers/SuspenseContent";
import PrivateLayout from "./components/Layout/PrivateLayout";
import AuthLayout from "./components/Layout/AuthLayout";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const LoginAdmin = lazy(() => import("./pages/LoginAdmin"));

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<SuspenseContent />}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/admin/login" element={<LoginAdmin />} />
            </Route>
            <Route element={<PrivateLayout />}>
              <Route path="/app/*" element={<Layout />} />
            </Route>
            <Route element={<PrivateLayout />}>
              <Route path="/app/admin/*" element={<Layout />} />
            </Route>
            <Route path="*" element={<Navigate to="/app/dashboard" />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
